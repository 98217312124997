
<template>
  <ClientOnly>
    <TairoCollapseLayout>
      <slot />
    </TairoCollapseLayout>

    <TairoPanels />
  </ClientOnly>
</template>
